* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-size: 14px;
    font-family: Sarabun, Helvetica, Arial, sans-serif;
    font-weight: 400;
}

:root {
    --background--primary: #011022;
    --color-primary: white;
    --color-red: #EA5455;
    --color-green: #28C76F;
    --color-gray: #1c233b;
    --color-green2: #30837b;
    --color-black: black;
    --color-brown: #94886D;
    --color-blandGreen: #31BAA0;
    --color-violet: #B9C7FF;
    --color-blandBlude: #3EC9D6;
    --color-red2: #B11922;
    --color-green2: #20AFA0;
    --color-green3: #3EC106;
    --color-gray2: #F1F3F4;
    --color-blue: #004AB1;
}

a {
    text-decoration: none;
}

.bg-gray2 {
    background-color: var(--color-gray2);
}

.color-blue {
    color: var(--color-blue);
}

.color-green3 {
    color: var(--color-green3);
}

.bg-green3 {
    background-color: var(--color-green3);
}

p,
i,
a,
h1,
h2,
h3,
h4,
h5,
h6 {
    padding-bottom: 0 !important;
    margin-bottom: 0 !important;
}
main.chat .list-message-chat{
    background: url('../img/nhan-vien-cham-soc-khach-hang-1-16866240351674.png');
    position: relative;
    background-size: cover;
   
}
main.chat .list-message-chat::before{
    content: ''; /* Cần thiết để pseudo-element hiển thị */
    position: absolute; /* Đặt lớp phủ đúng vị trí */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Màu lớp phủ với độ trong suốt */
   
}
.position-relative {
    position: relative;
}

.bg-transparent {
    background: transparent;
}

.text-justify {
    text-align: justify;
}

.color-green-2 {
    color: var(--color-green2);
}

.color-red-2 {
    color: var(--color-red2);
}

.bg-red-2 {
    background-color: var(--color-red2);
}

.color-white {
    color: var(--color-primary) !important;
}

.placeholder-white::placeholder {
    color: var(--color-primary) !important;
}

.boder-none:focus {
    box-shadow: none;
}

.cursor-pointer {
    cursor: pointer;
}

.body-web {
    width: 100vw;
    max-width: 100%;
    height: auto;
    min-height: 100vh;
}

.bg-black {
    background-color: var(--color-black);
}

.bg-white {
    background-color: var(--color-primary);
}

.color-white {
    color: var(--color-primary);
}

.color-black {
    color: var(--color-black);
}

section.main-container {
    margin-top: 80px;
}

body {
    background-color: var(--color-black);
}

.flex-class {
    display: flex;
    align-items: center;
}

section.main-container {
    width: 100%;
}

.ps-sidebar-root {
    position: fixed !important;
    top: 80px;
    height: auto;
    left: 0;
    bottom: 0;
    z-index: 10;

}

.main-custom {
    margin-top: 81px !important;
    margin-left: 121px !important;
}

@media (max-width: 767px) {
    .main-custom {
        margin-top: 0 !important;
        margin-left: 0 !important;
    }

    g.tick-value {
        display: none;
    }

    /* .semicircle-gauge svg{
        width: 100%;
    } */
}

.ps-menuitem-root.active,
.ps-menuitem-root a.active {
    color: var(--color-red2);
}

.css-ac4ouy>.ps-menu-button:hover {
    background: transparent !important;
}

.ps-menuitem-root.active a:hover,
.ps-menuitem-root.active a:focus {
    background: transparent;
}

.bg-primary-custom {
    background-color: var(--background--primary) !important;
}

.bg-red {
    background-color: var(--color-red) !important;
}

.bg-green {
    background-color: var(--color-green) !important;
}

.bg-gray {
    background-color: var(--color-gray) !important;
}

.color-green {
    color: var(--color-green2) !important;
}

.color-red {
    color: var(--color-red) !important;
}

.bg-brown {
    background-color: var(--color-brown) !important;
}

.bg-blandGreen {
    background-color: var(--color-blandGreen) !important;
}

.color-violet {
    color: var(--color-violet) !important;
}

.bg-blandBlude {
    background-color: var(--color-blandBlude) !important;
}

.height-100 {
    height: "100%"
}

.icon-close-code {
    position: absolute;
    top: 10px;
    z-index: 100;
    right: 10px;
}

.height-auto {
    height: auto;
}

.code-notification li {
    list-style-type: none;
    padding: 10px 15px;
    font-size: 16px;
}

.code-notification li.actice {
    position: relative;
}

.excavator {
    border-radius: 10px;
}

.code-notification li.actice::after {
    position: absolute;
    bottom: -5px;
    left: 0;
    content: "";
    width: 100%;
    height: 3px;
    background-color: #75313c;
}

.pie-chart-container {
    width: 200px;
    height: 200px;
    margin: 0 auto
}

.data-board {
    background-color: #28384A;
    padding: 20px;
    height: auto;
    border-radius: 10px;
}

.net-profit {
    width: 100%;
    height: 130px;
    border-radius: 10px;
}

.font-icon-setting {
    font-size: 25px;
    font-weight: bold;
}

.time-input-picker {
    width: 200px;
    height: 40px;
    outline: none;
    border: 1px solid #ddd;
    border-radius: 8px;
    color: white;
}

.time-input-picker::placeholder {
    color: white;
}

.search-time-data {
    border: none;
    outline: none;
    color: white;
    border-radius: 5px;
}

header {
    width: 100%;
    height: 80px;
    padding: 0 30px;
    font-size: 15px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
}

.select-chane-money {
    color: var(--color-primary) !important;
}

.select-chane-money option {
    background-color: var(--background--primary);
    color: var(--color-primary);
    padding: 5px 0;
}

.select-chane-money:focus {
    box-shadow: none !important;
}

.logo {
    padding: 0 10px;
    height: 100%;
}

.logo-img {
    height: 60px;
}

.infro-header {
    position: relative;
    width: auto;
}

.infro-header-item {
    width: 160px;
    padding: 6px 8px;
    border-radius: 5px;
    background: #414561;
    border-color: #414561;
    position: absolute;
    top: 25px;
    right: 0;
    z-index: 10;
    transition: 0.5s ease-in-out;
}

.card-box {
    padding: 6px 8px;
    background-color: #fa2843;
    border-radius: 5px;
}

.form-input {
    width: 100%;
    padding: 10px 0;
    border: 1px solid #ccc !important;
    border-radius: 8px;
    position: relative;
}

.form-input input {
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    color: var(--color-primary);
    padding-left: 25px;
}

.form-input .p-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    color: white;
}

.form-input .p-icon.icon-dollar {
    left: 8px;
}

.form-input .p-icon.icon-close {
    right: 8px;
}

.btn-acction {
    background-color: #1d233d;
    width: 100%;
    height: 40px;
    border: none;
    outline: none;
    color: white;
    border-radius: 8px;
}

.btn-acction-result {
    width: 100%;
    height: 50px;
    outline: none;
    border-radius: 5px;
    background: transparent;
    color: white;
    border: none;
}

.btn-loadding {
    color: var(--color-red) !important;
    border: 1px solid var(--color-red);
}

.custom-progress-bar {
    background-color: var(--color-red) !important;
    height: 5px;
}

.custom-progress-bar .progress-bar {
    background-color: var(--color-green) !important;
}

.modal-image {
    /* overflow: hidden; */

    width: 100%;
    height: 100px;
}

.modal-image img {
    width: 80px;
    height: 80px;
    object-position: center;
    object-fit: cover;
    border-radius: 50%;
}

.modal-infro-user {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
}

.modal-infro-user-modal {
    width: 100%;
    height: 100%;
}

.modal-content {
    width: 98%;
    min-height: 370px;
    max-width: 400px;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: #02142B;
}

.modal-container {
    width: 100%;
    height: 100%;
    position: relative;
}

.modal-header {
    width: 100%;
    height: 40px;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 100;
    padding: 20px 20px 0 20px;
}

.modal-header h3 {
    font-size: 16px;
}

.modal-header i.fa-xmark {
    font-size: 20px;
    cursor: pointer;
    transition: 0.5s ease-in-out;
}

.modal-header i.fa-xmark:hover {
    color: #75313c;
}

.modal-image {
    width: 80px;
    height: 80px;
}

.update-avatar {
    width: 120px;
    height: 40px;
    border-radius: 10px;
    border: #7367F0 1px solid;
    outline: none;
    color: #7367F0;
}

.form-user-infro input {
    border: 1px solid #c2c6dc !important;
    outline: none;
    box-shadow: none !important;
    color: #c2c6dc;
}

.form-infro-user {
    overflow-y: scroll;
    overflow-x: hidden;
    height: auto;
    max-height: 550px;
    min-height: 400px;
    padding-right: 10px;
}

@media (max-width: 480px) {
    .form-infro-user {
        height: auto;
        min-height: auto;
        max-height: none;
    }
}

.form-infro-user::-webkit-scrollbar {
    width: 4px;
}

.form-infro-user::-webkit-scrollbar-track {
    background: transparents;
    /* Màu nền của vùng thanh cuộn */
}

.form-infro-user::-webkit-scrollbar-thumb {
    background: #888;
    /* Màu của phần thanh cuộn */
    border-radius: 10px;
    /* Làm tròn các góc của thanh cuộn */
}

.form-infro-user::-webkit-scrollbar-thumb:hover {
    background: #555;
    /* Màu của phần thanh cuộn khi di chuột qua */
}

.form-user-infro input:focus {
    color: #c2c6dc !important;
}

.form-user-infro input::placeholder {
    color: #c2c6dc;
}

.text-verification p {
    font-size: 12px;
    font-weight: 100;
}

.btn-accuracy {
    color: #F59942;
    border: 1px solid #F59942;
    padding: 9px 28px;
    border-radius: 10px;
    cursor: pointer;
}

.total-money {
    background-color: #2E3C4C;
}

.total-money span {
    color: #708090;
    font-size: 18px;
    font-weight: bold;
}

.total-money {
    font-size: 35px;
    font-weight: 900;
}

.nav-tabs {
    border: none;
}

.tab-content li.nav-item button {
    background: transparent;
    color: white !important;
}

.tab-content li.nav-item button.nav-link.active {
    background: transparent;
    border: none;
    color: #E33750;
    position: relative;
}

.tab-content li.nav-item button.nav-link.active::after {
    content: "";
    width: 100%;
    height: 3px;
    background-color: #E33750;
    position: absolute;
    bottom: -10px;
    left: 0;
}

.tab-content li.nav-item button:hover {
    border: none !important;
}

.primary-pay-box {
    border: 1px solid #ddd;
    border-radius: 10px;
}

table.table tr {
    border-bottom: 1px solid #ddd;

}

table.table th,
table.table td {
    background: transparent !important;
    color: white;
}

.btn-transfer-money {
    background-color: #2177FF;
    color: white;
    width: 370px;
    height: 44px;
    border: none;
    outline: none;
    border-radius: 5px;
}

.transfer-box {
    max-width: 580px;
    height: 180px;
    border-radius: 10px;
    overflow: hidden;
    background-image: url(../img//backgroup.png);
}

.transfer-box span {
    font-size: 18px;
    font-weight: 550;
}

.titel-table p {
    font-size: 25px;
    font-weight: 550;
}

.ps-sidebar-root {
    border: none !important;
}

.slide-bar {
    width: 120px !important;
    min-width: 100px !important;
    word-wrap: break-word;
    font-size: 14px;
}

.slide-bar .ps-sidebar-container {
    background: transparent;
}

ul.css-ewdv3l li.ps-menuitem-root {
    padding: 10px 0;
}

ul.css-ewdv3l li.ps-menuitem-root a p {
    overflow-wrap: break-word;
    font-size: 14px !important;
}

.css-nwj8xd>.ps-menu-button:hover {
    background: transparent !important;
}

.update-password .modal-content {
    top: 50%;
    transform: translateY(-50%);
    left: 50%;
    transform: translateX(-50%);
    z-index: 100;
    height: 420px;
    max-height: 550px;
}

.update-password .modal-header {
    height: 60px;
    border: none;
}

.update-password .modal-header button.btn-close {
    color: white !important;
}

.update-password .modal-header .h4 {
    color: white;
}

.update-password .modal-body {
    margin-top: 70px;
}

.update-password .modal-body label {
    color: white;
}

.input-update-password {
    background-color: transparent;
    border-radius: 5px !important;
    color: white !important;
}

.input-update-password:focus {
    background-color: transparent;
    box-shadow: none;
    border-color: white
}

.update-password .modal-footer {
    border: none;
}

.btn-update-password {
    padding: 8px 12px;
    border: 1px solid #156c49;
    color: #156c49;
}

.icon-close-update-password {
    position: absolute;
    top: 5px;
    right: 5px;
    font-size: 20px;
    color: white;
    cursor: pointer;
    width: 40px;
    height: 40px;
    z-index: 110;
}

.login-form .modal-content {
    top: 50%;
    transform: translateY(-50%);
    left: 50%;
    transform: translateX(-50%);
    z-index: 100;
    height: 400px;
    max-height: 550px;
}
.register-form .modal-content{
   
    height: 500px !important;
}
.login-form .modal-header {
    height: 60px;
    border: none;
}

.login-form .modal-header button.btn-close {
    color: white !important;
}

.login-form .modal-header .h4 {
    color: white;
}

.login-form .modal-body {
    margin-top: 70px;
}

.login-form .modal-body label {
    color: white;
}

.input-login-form {
    background-color: transparent;
    border-radius: 5px !important;
    color: white !important;
}
.input-login-form::placeholder{
    font-size: 12px;
    color: white  !important;
}
.input-login-form:focus {
    background-color: transparent;
    box-shadow: none;
    border-color: white
}

.login-form .modal-footer {
    border: none;
}

.btn-login-form {
    padding: 8px 12px;
    border: 1px solid #156c49;
    color: #156c49;
}

.icon-close-login-form {
    position: absolute;
    top: 5px;
    right: 5px;
    font-size: 20px;
    color: white;
    cursor: pointer;
    width: 40px;
    height: 40px;
    z-index: 110;
}

a#tv-attr-logo {
    display: none;
}

.box-titel-home {
    height: 20px;
}

.font-weight-custom-bold {
    font-weight: 550;
}

.iframe-home {
    height: 50px !important;
}

.pay-modal .modal-content {
    max-width: 100%;

}

.pay-modal .modal-header {
    height: 60px;
    border: none;
}

.pay-modal .modal-header button.btn-close {
    color: white !important;
}

.pay-modal .modal-header .h4 {
    color: white;
}

.pay-modal .modal-body {
    height: auto;
}

.pay-modal .modal-body label {
    color: white;
}

.pay-modal-form {
    background-color: transparent;
    border-radius: 5px !important;
    color: white !important;
}

.pay-modal-form:focus {
    background-color: transparent;
    box-shadow: none;
    border-color: white
}

.pay-modal .modal-footer {
    border: none;
}

.btn-pay-modal {
    padding: 8px 12px;
    border: 1px solid #156c49;
    color: #156c49;
}

.icon-close-pay-modal {
    position: absolute;
    top: 5px;
    right: 5px;
    font-size: 20px;
    color: white;
    cursor: pointer;
    width: 40px;
    height: 40px;
    z-index: 110;
}

.input-recharge {
    width: 100%;
    height: 40px;
    border-radius: 10px;
    border: 1px solid var(--color-violet);
    position: relative;
    /* overflow: hidden; */
}

.input-recharge label {
    position: absolute;
    top: -10px;
    left: 20px;
    transition: 0.5s ease-in-out;
    background-color: transparent;
    font-size: 12px;
    z-index: 4;
    background-color: var(--background--primary);
    padding: 0 4px;
}

.input-recharge input {
    width: 100%;
    height: 100%;
    padding-left: 10px;
    border: none;
    outline: none;
    color: white;
}

.btn-recharge {
    width: 220px;
    height: 45px;
    background-color: var(--color-green);
    color: white;
    border: none;
    outline: none;
    border-radius: 10px;
}

.btn-withdraw-modal {
    width: 220px;
    height: 45px;
    border-radius: 10px;
    border: none;
    outline: none;
    background-color: var(--color-red);
}

.btn-load-more {
    padding: 10px 24px;
    border: none;
    outline: none;
    color: var(--color-primary);
    border-radius: 10px;
    background-color: var(--color-red);
}

.modal-change-user {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 1001;
}

.modal-change-user-modal {
    width: 100%;
    height: 100%;
}

.tab-user {
    width: 100%;
}

.tab-user li.nav-item {
    width: 49px;
    height: 49px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    background-color: var(--background--primary);
    border: 2px #1c3d61 solid !important;
}

.nav-tabs.tab-user li.nav-item .nav-link.active {
    border: none;
    color: var(--color-primary);
    background: #1C3D61;
}

.tab-user li.nav-item button:hover {
    border: none;
}

.tab-user li.nav-item button {
    width: 100%;
    height: 100%;
    color: var(--color-primary);
    background: transparent;
}

.btn-upload-img {
    width: 155px;
    height: 145px;
    border: none;
    outline: none;
    background-color: var(--color-primary);
    border-radius: 10px;
}

.scroll-y {
    overflow: hidden;
    overflow-y: scroll;
    height: 600px;
}

.scroll-y::-webkit-scrollbar {
    width: 4px;
    /* Chiều rộng của thanh cuộn */
}

.scroll-y::-webkit-scrollbar-track {
    background: transparent;
    /* Màu nền của vùng kéo thanh cuộn */
}

.scroll-y::-webkit-scrollbar-thumb {
    background: #888;
    /* Màu của thanh cuộn */
    border-radius: 6px;
    /* Bo góc của thanh cuộn */
}

.scroll-y::-webkit-scrollbar-thumb:hover {
    background: #555;
    /* Màu của thanh cuộn khi hover */
}

.boder-circle {
    border-radius: 50%;
}

.btn-step {
    width: 150px;
    height: 45px;
    border: 1px solid #3D4D5F;
    outline: none;
    border-radius: 10px;
    color: var(--color-primary);
    cursor: pointer;
}

.swiper {
    height: 450px;
}

.swiper.swiper-fade .swiper-wrapper img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.login-container .header {
    height: 60px;
}

ul.navigation li {
    list-style: none;
    padding: 10px 20px;
    border-radius: 26px;
    border: 1px solid var(--background--primary);
    cursor: pointer;
    font-weight: bold;
}

ul.navigation li.actice {
    background-color: var(--background--primary);
    color: var(--color-primary);
    border: none;
}

.boder-content {
    border: 1px solid #ccc;
    border-radius: 10px;
}

.img-class-content {
    height: 105px;
    object-fit: cover;
    object-position: center;
}

.height-150 {
    height: 180px !important;
}

.login-menu {
    height: auto;
    background-color: var(--color-gray2);
    color: var(--color-gray);
    border-radius: 10px;
}

.login-menu li {
    list-style: none;

}

.login-menu li a {
    text-decoration: none;
    color: var(--color-gray);
}

.image-s3 {
    height: 400px;
    object-fit: cover;
    object-position: center;
    border-radius: 10px;
}

.btn-buy {
    padding: 14px 34px;
    border: none;
    outline: none;
    background-color: #157347;
    color: var(--color-primary);
    border-radius: 10px;
}

.btn-buy-custom {
    position: absolute;
    top: 300px;
    left: 100px;
    transition: 0.5s ease-in-out;

}

.box-buy-excavator {
    overflow: hidden;
}

.box-buy:hover .btn-buy.btn-buy-custom {
    transition: 0.5s ease-in-out;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
}

/* .height-300{
    height: 300px !important;
}
footer{
    height: 380px;
}
footer img{
    object-fit: cover;
    object-position: center;
} */
li {
    list-style-type: none;
}

.img-chat {
    width: 40px;
    height: 40px;
    object-fit: cover;
    object-position: center;
    border-radius: 50%;
}

.list-user-chat {
    border: 1px solid #ccc;
    border-radius: 10px;
    overflow: hidden;
    overflow-y: scroll;
    height: 550px;
    scrollbar-width: thin;
    scrollbar-color: #888 #f1f1f1;
}

.list-user-chat::-webkit-scrollbar {
    width: 8px;
}

.list-user-chat::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
}

.list-user-chat::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
}

.list-user-chat::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.list-user-chat li {
    background-color: var(--color-gray2);
    cursor: pointer;
}
.list-user-chat li.active{
    background-color: var(--color-red);
    color: white;
    border-radius: 10px;
}
.list-message-chat{
    display: block;
    height: 500px;
    max-height: 600px;
   overflow: hidden;
    overflow-y: scroll;
    scrollbar-width: thin;
    scrollbar-color: #888 #f1f1f1;
}
.list-message-chat::-webkit-scrollbar {
    width: 8px;
}

.list-user-chat::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
}

.list-user-chat::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
}

.list-user-chat::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.chat-infor{
    border: 1px solid #ccc;
    border-radius: 10px;
}
.item-message{
    width: 250px;
    padding: 10px 20px;
    height: auto;
    border: 2px solid var(--color-primary);
    background-color: var(--color-gray);
    color: var(--color-primary);
    border-radius: 10px;
    margin: 10px;
    z-index: 10;
    border-color: #ffffff78;
    /* box-shadow: 5px 5px 5px var(--color-primary); */
}

.progress-indicator{

}

.tick-line{
    display:none;
}
.tick-value{
    display: none;
}

.value-text > text, .indicator-title{
    font-size: .8rem!important;
}

@media(max-width: 490px){
    .iframe-home{
        margin-top: 65px;
    }

    .three-indicator{
        .col-4{
            width: 30%;
        }
        .col-4:nth-child(2)
        {
            width: 40%;
        }
    }
}

.tradingview-widget-copyright{
    display: none;
}
#tradingview_2d7e4{
    height: 300px;
}